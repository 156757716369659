import { put, call, take, delay } from "redux-saga/effects";
import * as actionCreators from "../actions/AuthActions";
import { httpGet, httpPost } from "./../../service";
import { VC_BASE_URL, USER_TOKEN_API, API_SECRET_TOKEN, NFA_BASE_URL, NFA_CONFIG } from "../../config";

function* getValidTokenData(payload: {
    token: string;
    empId: string;
    username: string;
}) {
    try {
        yield put(actionCreators.getUserTokenLoadingAction());
        const config = {
            headers: {
                // Authorization: `${payload.token}`,
                token: API_SECRET_TOKEN,
                "x-teams-origin": window.location.origin
            },
        };
        const { data } = yield httpPost(
            VC_BASE_URL + USER_TOKEN_API,
            {
                empId: payload.empId,
                platform: "tml-dashboard",
                username: payload.username,
            },
            config
        );
        yield put(actionCreators.getUserTokenSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getUserTokenErrorAction(error.response.data.error)
        );
    }
}

function* getValidManagerTokenData(payload: {
    token: string;
    empId: string;
    username: string;
}) {
    try {
        yield put(actionCreators.getManagerTokenLoadingAction());
        const config = {
            headers: {
                // Authorization: `${payload.token}`,
                token: API_SECRET_TOKEN,
                "x-teams-origin": window.location.origin
            },
        };
        const { data } = yield httpPost(
            VC_BASE_URL + USER_TOKEN_API,
            {
                empId: payload.empId,
                platform: "tml-dashboard",
                username: payload.username,
            },
            config
        );

        yield put(actionCreators.getManagerTokenSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getManagerTokenErrorAction(error.response.data.error)
        );
    }
}

function* getValidAzAccessTokenData(payload: {
    access_token: string;
    payload: actionCreators.IGetAzAccessTokenPayLaod;
}) {
    try {
        yield put(actionCreators.getAzAccessTokenLoadingAction());

        const { data } = yield httpGet(
            "http://localhost:3002/api/get-az-token"
        );

        yield put(actionCreators.getAzAccessTokenSuccessAction(data));
    } catch (error: any) {
        yield put(
            actionCreators.getAzAccessTokenErrorAction(error.response.data.error)
        );
    }
}

export const fetchMultipleUsers = async (emails: string[], config = {}) => {
    const url = NFA_BASE_URL + NFA_CONFIG.GET_NFA_USER_TOKEN;
    const promises = emails.map(email => httpGet(`${url}${email}`, config));
    const results = await Promise.allSettled(promises);

    const successfulResponses = results
        .map((result, index) => result.status === "fulfilled" ? { email: emails[index], ...result.value.data } : null)
        .filter(result => result !== null) as { email: string, data: any; }[];

    const failedResponses = results
        .map((result, index) => result.status === "rejected" ? { email: emails[index], reason: result.reason } : null)
        .filter(result => result !== null) as { email: string, reason: any; }[];

    return {
        successfulResponses,
        failedResponses,
    };
};

function* getNFAUserValidTokenData(emails: string[]) {
    try {
        yield put(actionCreators.getNFAUserValidateTokenLoadingAction());
        const config = {
            headers: {
                token: API_SECRET_TOKEN,
                "x-teams-origin": window.location.origin
            },
        };

        // const { data } = yield httpGet(
        //     NFA_BASE_URL + NFA_CONFIG.GET_NFA_USER_TOKEN + email, config
        // );
        // yield put(actionCreators.getNFAUserValidateTokenSuccessAction(data));

        const { successfulResponses, failedResponses } = yield call(fetchMultipleUsers, emails, config);
        if (successfulResponses.length > 0) {
            // Dispatch a success action with only the data array
            yield put(actionCreators.getNFAUserValidateTokenSuccessAction(successfulResponses));
        }
        if (failedResponses.length > 0) {
            yield put(actionCreators.getNFAUserValidateTokenErrorAction("Some users could not be fetched."));
        }
    } catch (error: any) {
        yield put(
            actionCreators.getNFAUserValidateTokenErrorAction(error)
        );
    }
}

export function* watchGetValidTokenData() {
    while (true) {
        const { payload } = yield take(actionCreators.Actions.GET_VALID_USER_TOKEN);
        yield call(getValidTokenData, payload);
    }
}

export function* watchGetValidManagerTokenData() {
    while (true) {
        const { payload } = yield take(actionCreators.Actions.GET_VALID_MANAGER_TOKEN);
        yield call(getValidManagerTokenData, payload);
    }
}

export function* watchGetValidAzAccessTokenData() {
    while (true) {
        const { payload } = yield take(actionCreators.Actions.GET_VALID_AZ_ACCESS_TOKEN);
        yield call(getValidAzAccessTokenData, payload);
    }
}

export function* watchGetNFAUserValidTokenData() {
    while (true) {
        const { email } = yield take(actionCreators.Actions.GET_NFA_USER_VALID_TOKEN);
        yield call(getNFAUserValidTokenData, email);
    }
}
