import {
  Flex, Text, Popup, CloseIcon, Dropdown, RadioGroup, RedbangIcon, Datepicker, FlexItem, Segment, Button, Loader, Table, Divider, TextArea, Pill, Skeleton, Box, Input
} from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../App";
import { RootSchema } from "../../redux/reducer";
import * as ITSMAction from "../../redux/actions/ITSMAction";
import * as NotificationAction from "../../redux/actions/NotificationAction";
import { ErrorToast, SuccessToast } from "../ToastifyComponent";
import Attachments from "../Attachment";
import "./raiseTicket.scss";
import axios from "axios";
import { ITSM_BASE_URL, ITSM_API_CONFIG } from "../../config";
import { Constant } from "../../constant";
import * as microsoftTeams from "@microsoft/teams-js";
import { getAppInsights } from "./../../telemetry/telemetryService";
import { TraceEvent } from "../../constant";
interface IRaiseTicketProps {
  QueryData: IState
}

interface IRaiseItemObj {
  id: number;
  header: string;
  value: string;
  isActive?: boolean;
  category_id?: number;
  sub_category_id?: number;
  key?: string;
}

interface IRaiseTicketForm {
  category: IRaiseItemObj,
  subcategory: IRaiseItemObj,
  item: IRaiseItemObj,
  shortDescription: string,
  additionalComments: string,
  isInputActive: string
}

interface IDropdownValues {
  categoryList: IRaiseItemObj[];
  subCategory: IRaiseItemObj[];
  item: IRaiseItemObj[];
}

const RaiseTicket: React.FC<IRaiseTicketProps> = ({ QueryData }) => {
  const state = useSelector((state: RootSchema) => state);
  const { appInsights, trackEvent } = getAppInsights();
  const dispatch = useDispatch();
  const [ticketFormData, setTicketFormData] = React.useState<IRaiseTicketForm>({
    category: { id: 0, header: "", value: "" },
    subcategory: { id: 0, header: "", value: "" },
    item: { id: 0, header: "", value: "" },
    shortDescription: "",
    additionalComments: "",
    isInputActive: ""
  });
  const [dropDownValues, setDropdownValues] = React.useState<IDropdownValues>({
    categoryList: [],
    subCategory: [],
    item: [],
  });
  const [file, setFile] = React.useState<any>();
  const [refresh, setRefresh] = React.useState(0);
  const ValidateButton: boolean = ticketFormData.category.value !== "" && ticketFormData.subcategory.value !== "" && ticketFormData.item.value !== "" && ticketFormData.shortDescription.trim() !== "" && ticketFormData.additionalComments.trim() !== "";

  const submitHandler = async () => {
    const payload: ITSMAction.ICreateTicketPayload = {
      type: "Incident",
      loggedInUsername: QueryData.userId as string,
      category: ticketFormData.category.value,
      subcategory: ticketFormData.subcategory.value,
      item: ticketFormData.item.value,
      priority: "P4",
      severity: "LOW",
      assigned_group: "TCS_SERVICE_DESK",
      shortDescription: ticketFormData.shortDescription,
      additionalComments: ticketFormData.additionalComments,
      channel: Constant.SUPPORT_CHANNEL_NAME
    };
    dispatch(ITSMAction.createTicketDataAction(state.itsmData.getTokenData.access_token, payload));
  };

  const resetFormHandler = () => {
    setTicketFormData({
      category: { id: 0, header: "", value: "" },
      subcategory: { id: 0, header: "", value: "" },
      item: { id: 0, header: "", value: "" },
      shortDescription: "",
      additionalComments: "",
      isInputActive: ""
    });
  };

  React.useEffect(() => {
    if (!state.itsmData.getTokenData.isSuccess) {
      dispatch(ITSMAction.getTokenDataAction());
    }
  }, []);


  function addAttachment() {
    const attachmentRequest = file.map(async (data: any) => {
      const fd = new FormData();
      fd.append("file", data);
      fd.append("loggedInUsername", QueryData.userId as string);
      fd.append("ticketIdNumber", state.itsmData.createTicket.ticketId);

      const config: any = {
        headers: {
          Authorization: `Bearer ${state.itsmData.getTokenData.access_token}`,
          env: process.env.REACT_APP_ENV
        },
      };

      const headers = {
        Authorization: `Bearer ${state.itsmData.getTokenData.access_token}`,
        env: process.env.REACT_APP_ENV,
        "Content-Type": "multipart/form-data"
      };

      return axios.post(ITSM_BASE_URL + ITSM_API_CONFIG.ADD_ATTACHMENT, fd, config);

    });

    Promise.allSettled(attachmentRequest).then((results: any) => {
      let successCount = 0;
      for (const i in results) {
        if (results[i].status === "fulfilled" && results[i].value.status == 200) {
          successCount++;
        } else {
          ErrorToast(file[i].name + "file failed to attached with ticket id " + state.itsmData.addAttachment.ticketId);
        }
      }
      const attachFile = [];
      for (const i in file) {
        attachFile.push(file[i].name);
      }
      const payload = {
        type: "create",
        ticketType: "incident",
        empId: QueryData.employeeId as string,
        userObjId: QueryData.userObjectId as string,
        cardDetails: {
          ticketId: state.itsmData.createTicket.ticketId,
          shortDescription: ticketFormData.shortDescription,
          attachment: attachFile
        },
        env: process.env.REACT_APP_ENV === "production" ? null : QueryData.app_env === "staging" ? QueryData.app_env : "dev"
      };
      trackEvent(appInsights, TraceEvent.ITSM_INCIDENT_CREATE, payload);
      dispatch(NotificationAction.postNotificationDataAction(payload));
      const successTxt = successCount === 1 ? "File is attached with ticket id :- " + state.itsmData.createTicket.ticketId : successCount + " files are attached with ticket id :- " + state.itsmData.createTicket.ticketId;
      SuccessToast(successTxt);
      setRefresh(1);
      setFile([]);
    })
      .catch((error) => {
        setFile([]);
      });
  }

  React.useEffect(() => {
    if (state.itsmData.createTicket.isSuccess && state.itsmData.createTicket.ticketId !== "" && ValidateButton) {
      if (file) {
        addAttachment();
      } else {
        const payload = {
          type: "create",
          empId: QueryData.employeeId as string,
          ticketType: "incident",
          userObjId: QueryData.userObjectId as string,
          cardDetails: {
            ticketId: state.itsmData.createTicket.ticketId,
            shortDescription: ticketFormData.shortDescription,
            attachment: []
          },
          env: process.env.REACT_APP_ENV === "production" ? null : QueryData.app_env === "staging" ? QueryData.app_env : "dev"
        };
        dispatch(NotificationAction.postNotificationDataAction(payload));
      }
      SuccessToast("Ticket id: " + state.itsmData.createTicket.ticketId);
      resetFormHandler();
      setTimeout(() => {
        microsoftTeams.tasks.submitTask({}, process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_ID : process.env.REACT_APP_ENV === "staging" ? process.env.REACT_APP_STAG_ID : process.env.REACT_APP_ID);
      }, 1000);
    } else if ((state.itsmData.createTicket.isError || (state.itsmData.createTicket.isSuccess && state.itsmData.createTicket.ticketId === "")) && ValidateButton) {
      ErrorToast(state.itsmData.createTicket.message);
    }
  }, [state.itsmData.createTicket.isSuccess]);

  const handleFiles = (files: any) => {
    if (files.length > 0) {
      setFile(files);
    }
  };

  React.useEffect(() => {
    if (state.itsmData.getItsmCategoryData.isSuccess && ticketFormData.isInputActive === "category") {
      const data = state.itsmData.getItsmCategoryData.data.map((item) => {
        return {
          id: item.id,
          header: item.name,
          value: item.name,
          isActive: item.is_active,
          key: item.id + Date.now().toString()
        };
      });
      setDropdownValues({ ...dropDownValues, categoryList: data });
    } else if (state.itsmData.getItsmSubCategoryData.isSuccess && ticketFormData.isInputActive === "subcategory") {
      const data = state.itsmData.getItsmSubCategoryData.data.map((item) => {
        return {
          id: item.id,
          header: item.name,
          value: item.name,
          isActive: item.is_active,
          key: item.id + Date.now().toString()
        };
      });
      setDropdownValues({ ...dropDownValues, subCategory: data });
    } else if (state.itsmData.getItsmItemData.isSuccess && ticketFormData.isInputActive === "items") {
      const data = state.itsmData.getItsmItemData.data.map((item) => {
        return {
          id: item.id,
          header: item.name,
          value: item.name,
          isActive: item.is_active,
          key: item.id + Date.now().toString()
        };
      });
      setDropdownValues({ ...dropDownValues, item: data });
    }
  }, [state.itsmData.getItsmCategoryData.isSuccess, state.itsmData.getItsmSubCategoryData.isSuccess, state.itsmData.getItsmItemData.isSuccess, ticketFormData.isInputActive]);

  return (
    <React.Fragment>
      {
        state.itsmData.getTokenData.isSuccess ?
          <Flex column>
            <Flex column gap="gap.small" padding="padding.medium" styles={QueryData.isTaskModuleActive ? { height: "calc(100vh - 55px)", overflow: "auto" } : { height: "calc(100vh - 110px)", overflow: "auto" }}>
              <Flex gap="gap.small" padding="padding.medium" column={state.appConfig.dimension.deviceWidth >= 600 ? false : true} >
                <Flex column gap="gap.small" fill>
                  <Text content="Category*" weight="semibold" />
                  <Dropdown
                    search
                    inverted
                    clearable={ticketFormData.category.value === "" ? false : true}
                    className="inputBody"
                    fluid
                    placeholder="Select Category"
                    items={dropDownValues.categoryList}
                    onOpenChange={(e, v) => {
                      const checkOpen = v.open !== undefined ? v.open : true;
                      checkOpen && !state.itsmData.getItsmCategoryData.isSuccess && dispatch(ITSMAction.getItsmCategoryDataAction());
                      setTicketFormData({ ...ticketFormData, isInputActive: "category" });
                    }}
                    noResultsMessage={
                      <Flex fill vAlign="center" hAlign="center" >
                        <Text content="No Category Found" />
                      </Flex>
                    }
                    loading={state.itsmData.getItsmCategoryData.isLoading}
                    loadingMessage={
                      <Flex fill vAlign="center" hAlign="center" >
                        <Text content="Loading..." />
                      </Flex>
                    }
                    value={ticketFormData.category.value}
                    onChange={(e: any, val: any) => val.value === null ?
                      setTicketFormData({
                        ...ticketFormData,
                        category: { id: 0, header: "", value: "" },
                        subcategory: { id: 0, header: "", value: "" },
                        item: { id: 0, header: "", value: "" }
                      }) : setTicketFormData({
                        ...ticketFormData,
                        category: val.value,
                        subcategory: { id: 0, header: "", value: "" },
                        item: { id: 0, header: "", value: "" }
                      })}
                  />
                </Flex>
                <Flex column gap="gap.small" fill>
                  <Text content="Sub-Category*" weight="semibold" />
                  <Dropdown
                    search
                    inverted
                    clearable={ticketFormData.subcategory.value === "" ? false : true}
                    className="inputBody"
                    fluid
                    disabled={ticketFormData.category.value === ""}
                    placeholder="Sub-Category"
                    items={dropDownValues.subCategory}
                    onOpenChange={(e, v) => {
                      const checkOpen = v.open !== undefined ? v.open : true;
                      checkOpen && dispatch(ITSMAction.getItsmSubCategoryDataAction(ticketFormData.category.id));
                      setTicketFormData({ ...ticketFormData, isInputActive: "subcategory" });
                    }}
                    noResultsMessage={
                      <Flex fill vAlign="center" hAlign="center" >
                        <Text content="No Sub-Category Found" />
                      </Flex>
                    }
                    loading={state.itsmData.getItsmSubCategoryData.isLoading}
                    loadingMessage={
                      <Flex fill vAlign="center" hAlign="center" >
                        <Text content="Loading..." />
                      </Flex>
                    }
                    value={ticketFormData.subcategory.value}
                    onChange={(e: any, val: any) => val.value === null ?
                      setTicketFormData({
                        ...ticketFormData,
                        subcategory: { id: 0, header: "", value: "" },
                        item: { id: 0, header: "", value: "" }
                      }) : setTicketFormData({
                        ...ticketFormData,
                        subcategory: val.value,
                        item: { id: 0, header: "", value: "" }
                      })}
                  />
                </Flex>
              </Flex>
              <Flex gap="gap.small" padding="padding.medium" >
                <Flex column gap="gap.small" fill>
                  <Text content="Item*" weight="semibold" />
                  <Dropdown
                    search
                    inverted
                    clearable={ticketFormData.item.value === "" ? false : true}
                    className="inputBody"
                    fluid
                    value={ticketFormData.item.value}
                    disabled={ticketFormData.subcategory.value === ""}
                    placeholder="Select Item"
                    items={dropDownValues.item}
                    onOpenChange={(e, v) => {
                      const checkOpen = v.open !== undefined ? v.open : true;
                      checkOpen && dispatch(ITSMAction.getItsmItemDataAction(ticketFormData.subcategory.id));
                      setTicketFormData({ ...ticketFormData, isInputActive: "items" });
                    }}
                    noResultsMessage={
                      <Flex fill vAlign="center" hAlign="center" >
                        <Text content="No Items Found" />
                      </Flex>
                    }
                    loading={state.itsmData.getItsmItemData.isLoading}
                    loadingMessage={
                      <Flex fill vAlign="center" hAlign="center" >
                        <Text content="Loading..." />
                      </Flex>
                    }
                    onChange={(e: any, val: any) => val.value === null ?
                      setTicketFormData({
                        ...ticketFormData,
                        item: { id: 0, header: "", value: "" }
                      }) : setTicketFormData({
                        ...ticketFormData,
                        item: val.value
                      })}
                  />
                </Flex>
              </Flex>
              <Flex padding="padding.medium" column gap="gap.small" >
                <Text content="Short Description*" weight="semibold" />
                <Input
                  className="inputBody"
                  inverted
                  clearable={ticketFormData.shortDescription === "" ? false : true}
                  placeholder="Type Description"
                  required
                  value={ticketFormData.shortDescription}
                  fluid
                  onChange={(e: any, val: any) => setTicketFormData((prev) => {
                    return {
                      ...prev,
                      shortDescription: val.value === null ? "" : val.value.trimStart()
                    };
                  })}
                />
              </Flex>
              <Flex column gap="gap.small" padding="padding.medium" >
                <Text content="Additional Comments*" weight="semibold" />
                <TextArea
                  className="inputBody"
                  inverted
                  placeholder="Type Description"
                  required
                  value={ticketFormData.additionalComments.trimStart()}
                  fluid
                  rows={5}
                  onChange={(e: any, val: any) => setTicketFormData((prev) => {
                    return {
                      ...prev,
                      additionalComments: val.value.trimStart()
                    };
                  })}
                />
              </Flex>
              <Flex column gap="gap.small" padding="padding.medium">
                <Flex gap="gap.medium" vAlign="center">
                  <Text content="Attachments (Optional)" weight='semibold' styles={{ marginBottom: "0px !important" }} />
                  {/* <Popup trigger={<Button icon={<RedbangIcon size="small" rotate={180}  />} flat iconOnly />} position="after" align="center" pointing content={{ content: ToolTipContentAttachment(), styles: { maxWidth: "300px" } }} /> */}
                </Flex>
                <Attachments handleFiles={handleFiles} maxFiles={5} refresh={refresh} />
              </Flex>
            </Flex>
            <Divider fitted />
            <Flex gap="gap.medium" hAlign='end' padding='padding.medium'  >
              {/* <Button content="Reset" onClick={resetFormHandler} /> */}
              <Button disabled={!ValidateButton} loading={state.itsmData.createTicket.isLoading} content="Submit" primary onClick={submitHandler} />
            </Flex>
          </Flex>
          :
          <Skeleton animation='pulse'>
            <Flex column gap="gap.small" padding="padding.medium" styles={{ position: "relative", height: QueryData.isTaskModuleActive ? "calc(100vh - 55px)" : "calc(100vh - 110px)", overflow: "auto" }}>
              <Flex gap="gap.small" padding="padding.medium" column={state.appConfig.dimension.deviceWidth >= 600 ? false : true}>
                <Flex column gap="gap.small" fill>
                  <Skeleton.Text style={{ width: "30%" }} />
                  <Skeleton.Input fluid />
                </Flex>
                <Flex column gap="gap.small" fill>
                  <Skeleton.Text style={{ width: "30%" }} />
                  <Skeleton.Input fluid />
                </Flex>
              </Flex>
              <Flex column gap="gap.small" padding="padding.medium" >
                <Skeleton.Text style={{ width: "15%" }} />
                <Skeleton.Input fluid />
              </Flex>
              <Flex column gap="gap.small" padding="padding.medium" >
                <Skeleton.Text style={{ width: "15%" }} />
                <Skeleton.Input fluid />
              </Flex>
              <Flex column gap="gap.small" padding="padding.medium" >
                <Skeleton.Text style={{ width: "15%" }} />
                <Skeleton.Input fluid styles={{ height: "6rem" }} />
              </Flex>
            </Flex>
            <Divider fitted />
            <Divider fitted />
            <Flex gap='gap.medium' padding='padding.medium' vAlign="center" hAlign='end'>
              <Skeleton.Button />
              <Skeleton.Button />
            </Flex>
          </Skeleton>
      }
    </React.Fragment>
  );
};
export default RaiseTicket;